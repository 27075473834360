
import Swal from "sweetalert2/dist/sweetalert2.js";
import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getPanel } from "@/api/encounter.api";
import Multiselect from "@vueform/multiselect";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, required } from "@vuelidate/validators";
import {
  addFacility,
  checkDefaultCodesCodes,
  deleteFacility,
  fillCodes,
  getDefaultFacility,
  getFacility,
  updateFacility,
} from "@/api/facility.api";
import { useRoute, useRouter } from "vue-router";
import { Facility } from "@/modules/facility/facility.model";
import { getDictionary, getTypeOfBillCode } from "@/api/dictionaries.api";
import { getProviders } from "@/api/provider.api";
import { Provider } from "@/modules/provider/provider.module";
import State from "@/components/ABilling/StateComponent.vue";
import Country from "@/components/ABilling/CountryComponent.vue";
import PhoneNumberComponent from "@/components/ABilling/PhoneNumberComponent.vue";
import { Patient } from "@/modules/patient/patient.model";

export interface Data {
  defCodesFillEnabled: boolean;
  isDirty: boolean;
  facility?: Facility;
  previousFacility?: Facility;
  createNewMode: boolean;
  panel: { facilities: any[]; patients: Patient[]; providers: any };
  dict: {
    typeOfFacility: [];
    typeOfCare: [];
    frequency: [];
    dischargeStatus: [];
    admissionSource: [];
    boolList: { code?: boolean | null; name: string }[];
    placeOfService: [];
    attendingProviders: Provider[];
    billingFacilities: any[];
    statuses: {
      code: string;
      name: string;
    }[];
  };
}

export default defineComponent({
  name: "Facility Page",
  components: {
    Multiselect,
    State,
    Country,
    PhoneNumberComponent,
  },
  props: ["facilityId"],
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          cancelButton: "ab-button-secondary",
          confirmButton: "ab-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDenied) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  setup(props) {
    let organizationId = ref<string | null>("");
    const router = useRouter();
    const route = useRoute();
    let data = reactive<Data>({
      defCodesFillEnabled: false,
      isDirty: false,
      facility: undefined,
      createNewMode: true,
      panel: { facilities: [], patients: [], providers: [] },
      dict: {
        typeOfFacility: [],
        typeOfCare: [],
        frequency: [],
        dischargeStatus: [],
        admissionSource: [],
        statuses: [],
        placeOfService: [],
        attendingProviders: [],
        billingFacilities: [],
        boolList: [
          { code: true, name: "Yes" },
          { code: false, name: "No" },
        ],
      },
    });

    const postalReg = helpers.regex(/^\d{5}-\d{4}$|\d{9}/);
    const npiReg = helpers.regex(/\d{10}/);
    const taxonomyReg = helpers.regex(/^[a-zA-Z0-9]{10}$/);
    const taxIdReg = helpers.regex(/\d{9}/);

    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im // eslint-disable-line
    const isPhone = helpers.regex(phoneRegex);

    const billingReq = (data) =>
      helpers.withParams(
        { type: "billingReq", value: data },
        (value: any) => helpers.req(value) || data.facility.billingProvider 
      );

    const billingReqFacilityBillingProvider = (data) =>
      helpers.withParams(
        { type: "billingReqFacilityBillingProvider", value: data },
        (value: any) => helpers.req(value) || data.facility.billingProvider
      );

    const facilityRules = {
      facility: {
        name: { required: helpers.withMessage("Required", required) },
        address: { required: helpers.withMessage("Required", required) },
        city: { required: helpers.withMessage("Required", required) },
        state: { required: helpers.withMessage("Required", required) },
        country: { required: helpers.withMessage("Required", required) },
        postal: {
          required: helpers.withMessage("Required", required),
          maxLength: maxLength(15),
        },
        payToAddress: { required: helpers.withMessage("Required", required) },
        payToCity: { required: helpers.withMessage("Required", required) },
        payToState: { required: helpers.withMessage("Required", required) },
        payToCountry: { required: helpers.withMessage("Required", required) },
        payToPostal: {
          required: helpers.withMessage("Required", required),
          maxLength: maxLength(15),
        },
        npi: {
          required: helpers.withMessage("Required", required),
          npiReg: helpers.withMessage("10 numbers required", npiReg),
          maxLength: maxLength(10),
        },
        taxId: {
          required: helpers.withMessage("Required", required),
          taxIdReg: helpers.withMessage("9 numbers required", taxIdReg),
          maxLength: maxLength(9),
        },
        cliaId: {
          taxIdReg: helpers.withMessage("9 numbers required", taxIdReg),
          maxLength: maxLength(9),
        },
        taxonomy: {
          taxIdReg: helpers.withMessage("10 characters required", taxonomyReg),
          maxLength: maxLength(10),
        },
        billingProvider: {
          required: helpers.withMessage("Required", required),
        },
        status: { required: helpers.withMessage("Required", required) },
        phone: {
          mustBeCool: helpers.withMessage("Phone number is invalid", isPhone),
        },
        billingFacilityId: {
          billingReq: helpers.withMessage("Required", billingReq(data)),
        },
        facilityBillingProviderId: {
          billingReqFacilityBillingProvider: helpers.withMessage("Required", billingReqFacilityBillingProvider(data)),
        },
      },
    };

    const breadcrumbPath = decodeURIComponent(
      String(route.query?.breadcrumb ?? "back")
    );

    watch(
      () => data.facility,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          data.previousFacility &&
          JSON.stringify(currentValue) != JSON.stringify(data.previousFacility)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    let v$ = useVuelidate(facilityRules, data as never);

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Facility");
      data.panel = await getPanel();
      data.dict.statuses = [
        { code: "active", name: "Active" },
        { code: "inactive", name: "Inactive" },
      ];
      const typeOfBillCodes = await getTypeOfBillCode();

      data.dict.typeOfFacility = typeOfBillCodes.filter((item) => {
        return item.level == 1;
      });
      data.dict.typeOfCare = typeOfBillCodes.filter((item) => {
        return item.level == 2;
      });
      data.dict.frequency = typeOfBillCodes.filter((item) => {
        return item.level == 3;
      });

      data.dict.placeOfService = await getDictionary(11, "");
      data.dict.admissionSource = await getDictionary(8, "");
      data.dict.dischargeStatus = await getDictionary(9, "");
      data.dict.attendingProviders = await getProviders();
      data.dict.attendingProviders = data.dict.attendingProviders.filter(
        (item) => {
          return item.attending;
        }
      );
      if (props.facilityId) {
        data.facility = await getFacility(props.facilityId);
        data.defCodesFillEnabled = !(await checkDefaultCodesCodes(
          props.facilityId
        ));
        data.createNewMode = false;
      } else {
        data.createNewMode = true;
        data.facility = {
          name: "",
          address: "",
          city: "",
          state: null,
          country: "1",
          postal: null,
          payToState: null,
          payToCountry: null,
          payToPostal: null,
          npi: "",
          taxId: "",
          cliaId: "",
          billingProvider: false,
          status: "active",
          isDefault: false,
          billingFacilityId: null,
          facilityBillingProviderId: null,
          facilityFrequency: null,
          facilityTypeOfCare: null,
          facilityTypeOfFacility: null,
        };
      }
      data.dict.billingFacilities = data.panel.facilities.filter((c) => {
        return c.billingProvider && c.id != data.facility?.id;
      });
      //US default
      if (data.facility && !data.facility.country) {
        data.facility.country = "1";
      }
      data.previousFacility = JSON.parse(JSON.stringify(data.facility));
    });

    async function cancel() {
      if (route.path.includes("facilityPageAfterEncounter")) {
        router.go(-1);
      } else {
        router.push(breadcrumbPath);
      }
    }

    function checkPostal() {
      if (data.facility?.postal?.toString().length == 5) {
        data.facility.postal = data.facility?.postal + "0000";
      }
    }

    async function addItem() {
      const result = await v$.value.$validate();
      if (result) {
        const res = await addFacility(data.facility);
        if (res) {
          data.createNewMode = false;
          data.isDirty = false;
          router.push({ name: "facilitiesPage" });
        }
      }
    }

    async function updateItem() {
      const result = await v$.value.$validate();
      if (result) {
        Swal.fire({
          title: "Would you like to apply these changes to all Open Claims?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            cancelButton: "ab-button-secondary",
            confirmButton: "ab-button",
          },
        }).then(async (result) => {
          if (result.isConfirmed && data.facility) {
            data.facility.updateClaimsEvent = true;
          } else if (data.facility) {
            data.facility.updateClaimsEvent = false;
          }
          await updateFacility(data.facility);
          data.previousFacility = JSON.parse(JSON.stringify(data.facility));
          data.isDirty = false;
          if (route.path.includes("facilityPageAfterEncounter")) {
            router.go(-1);
          } else {
            router.push(breadcrumbPath);
          }
        });
      }
    }

    function updatePayToAddressOnForm(event) {
      if (event.target.checked && data.facility) {
        data.facility.payToCountry = data.facility.country;
        data.facility.payToAddress = data.facility.address;
        data.facility.payToAddress2 = data.facility.address2;
        data.facility.payToCity = data.facility.city;
        data.facility.payToState = data.facility.state;
        data.facility.payToPostal = data.facility.postal;
        updatePostalToFormat(data.facility.payToPostal);
      }
    }

    function updatePostalToFormat(postal) {
      if (postal && data.facility?.payToPostal) {
        data.facility.payToPostal.replace("-", "");
        var zip = data.facility.payToPostal.toString();
        if (zip.length == 5 && zip.substring(5, 9) != "0000") {
          data.facility.payToPostal = zip + "-0000";
        } else if (zip.length == 9) {
          data.facility.payToPostal =
            zip.substring(0, 5) + "-" + zip.substring(5, 9);
        }
      }
    }

    async function deleteItem() {
      await deleteFacility(data.facility);
    }

    async function selectDefault(event) {
      if (data.facility && !data.facility.isDefault) {
        Swal.fire({
          title:
            "Are you sure you want to remove this Facility as the default value?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            cancelButton: "ab-button-secondary",
            confirmButton: "ab-button",
          },
        }).then((result) => {
          if (!result.isConfirmed && data.facility) {
            data.facility.isDefault = true;
          }
        });
      } else {
        let text = "Are you sure you want to set this Facility as default?";
        const defaultFacility = await getDefaultFacility();
        if (defaultFacility) {
          text =
            text +
            " Current default Facility Billing Provider is " +
            defaultFacility.name;
        }
        Swal.fire({
          title: text,
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            cancelButton: "ab-button-secondary",
            confirmButton: "ab-button",
          },
        }).then((result) => {
          if (!result.isConfirmed && data.facility) {
            data.facility.isDefault = false;
          }
        });
      }
    }

    function checkBillingProvider(event) {
      if (data.facility && data.facility.billingProvider) {
        data.facility.billingFacilityId = null;
        data.facility.facilityBillingProviderId = null;
      }

      if (
        data.facility &&
        !data.facility.billingProvider &&
        data.facility.isDefault
      ) {
        Swal.fire({
          title:
            "Are you sure you want to remove this Facility as the default value?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            cancelButton: "ab-button-secondary",
            confirmButton: "ab-button",
          },
        }).then((result) => {
          if (data.facility) {
            if (!result.isConfirmed) {
              data.facility.isDefault = true;
              data.facility.billingProvider = true;
            } else {
              data.facility.isDefault = false;
            }
          }
        });
      }
    }

    async function fillDefaultCodes() {
      const res = await fillCodes(data.facility?.id);
      if (res) {
        Swal.fire({
          text: "the default codes have been loaded",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          customClass: {
            confirmButton: "ab-button",
          },
        });

        data.defCodesFillEnabled = !(await checkDefaultCodesCodes(
          props.facilityId
        ));
      }
    }

    return {
      organizationId,
      data,
      addItem,
      updateItem,
      updatePayToAddressOnForm,
      updatePostalToFormat,
      deleteItem,
      cancel,
      selectDefault,
      checkBillingProvider,
      fillDefaultCodes,
      checkPostal,
      v$,
    };
  },
});
